import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby"
import PageNotFound from "../images/pagenotfound.jpg"
import SEO from "../components/seo";
import Header from '../components/Header/Header-2';

const NotFound = () => {
    const seo = {metaDesc: '404 - Page Not Found'}
    return (
        <Layout>
            <SEO title="404 - Page Not Found" canonical="/404/"  seo={seo} />
            <Header home={false}/>
            <main>
                <section class="notfound-banner-title py-5">
                    <div class="container">
                        <h2 class="page-title text-white">Not Found</h2>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="row">
                            <div className="col-12">
                                <h1>404: Page Not Found</h1>
                                <p>
                                    <Link to="/">Go to Home Page</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </Layout>
    )
}

export default NotFound